import logo from "./logo.svg";
import "./App.css";
import {
  Button,
  Container,
  Image,
  Header,
  Grid,
  Divider,
  Item,
} from "semantic-ui-react";
function App() {
  return (
    <Container>
      <Grid
        stackable
        style={{ height: "100vh", paddingTop: "10vh" }}
        verticalAlign="middle"
      >
        <Grid.Row>
          <Grid.Column width={3}></Grid.Column>
          <Grid.Column width={5} textAlign="right">
            <Image rounded size="medium" textAlign="right" src="chagall.png" />
          </Grid.Column>
          <Grid.Column width={5} textAlign="left">
            <Header as="h3">EOGF Partners는 투자회사입니다.</Header>
            <Divider />

            <Header as="h4">
              우리는 투자를 통해 “미래를 현재화 시키고 널리 퍼지게” 하는 것을
              목표로 합니다.
            </Header>
            <p>
              함께 하는 회사들과 함께 고민하고, 경험을 나누고, 인재를 구하고,
              시장에 알리며, 더 큰 기회와 만나는 것을 도우면서 함께 성장하기를
              바랍니다.
            </p>
            <p>
              M&A 혹은 상장(IPO)을 통한 좋은 이별을 바라지만, 실패라는 아픈
              성장통을 겪더라도 성숙과 배움을 위한 서로의 뜻 깊은 만남이
              되었기를 한껏 바랍니다.
            </p>
            <Button href="mailto://info@eogfpartners.com">Email us</Button>
          </Grid.Column>
          <Grid.Column width={3}></Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

export default App;
